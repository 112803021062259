<template>
  <div class="main-div">
    <div class="vasion-page-title top-div">
      Date Options
    </div>
    <div class="grid-div vasion-html-table-default">
      <table>
        <thead>
          <tr>
            <th>
              <label class="vasion-html-table-header">Index Field</label>
            </th>
            <th>
              <label class="vasion-html-table-header">Comparison Type</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(criteria, index) in criteriaFields" :key="index">
            <td>
              {{ criteria.fieldTitle }}
            </td>
            <td>
              <VasionDropList
                v-slot="slotItem"
                v-model="criteria.selectedComparisonType"
                :dataArray="dateCompare"
                title=""
                placeholder=""
                width="100%"
                type="plain-list"
                displayName="name"
                valueName="id"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w100 right-align">
      <VasionButton id="btnCancelStaticFields" :classProp="'primary-light'" @vasionButtonClicked="cancelClick()">
        CANCEL
      </VasionButton>
      <VasionButton id="btnOKStaticFields" :classProp="'primary'" @vasionButtonClicked="okClick()">
        OK
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexSearchDateOptions',
  props: {
    propAllDateFields: {
      type: Array,
      default: () => [],
      required: true,
    },
    propCriteriaFields: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data: function () {
    return {
      criteriaFields: [],
      dateCompare: [
        {
          name: 'Equals',
          id: 1,
        },
        {
          name: 'Greater Than',
          id: 6,
        },
        {
          name: 'Less Than',
          id: 7,
        },
        {
          name: 'Greater Than or Equal To',
          id: 8,
        },
        {
          name: 'Less Than or Equal To',
          id: 9,
        },
        {
          name: 'Range',
          id: 10,
        },
      ],
    }
  },
  created() {
    if (!this.propAllDateFields || this.propAllDateFields.length <= 0) {
      return
    }

    // eslint-disable-next-line
    this.propAllDateFields.map((element) => {
      const foundField = this.propCriteriaFields.find((criteriaElement) => {
        return criteriaElement.indexFieldID === element.FieldID
      })

      const comparisonTypeID = foundField ? foundField.comparisonType : 10
      const compareType = this.dateCompare.find((compareElement) => {
        return compareElement.id === comparisonTypeID
      })

      this.criteriaFields.push(
        {
          indexFieldID: element.FieldID,
          comparisonType: comparisonTypeID,
          fieldTitle: element.DisplayName,
          selectedComparisonType: compareType,
        },
      )
    }, this)
  },
  methods: {
    cancelClick() {
      this.$emit('cancelClick')
    },
    okClick() {
      this.criteriaFields = this.criteriaFields.map((element) => {
        return {
          indexFieldID: element.indexFieldID,
          comparisonType: element.selectedComparisonType.id,
          fieldTitle: element.DisplayName,
        }
      })

      this.$emit('okClick', this.criteriaFields)
    },
  },
}
</script>

<style lang="scss" scoped>

  .main-div {
    height: 400px;
    background-color: white;
    margin: 32px 24px 32px 24px;
  }

  .grid-div {
    height: 315px;
    overflow: auto;
  }

  .left-align {
    text-align: left;
  }

  .right-align {
    text-align: right;
  }

  .top-div {
    height: 50px;
  }

  .w100 {
    width: 100%;
  }

  td {
    padding-right: 10px;
    padding-left: 10px;
  }

</style>
