<template>
  <div class="main-div">
    <div class="vasion-page-title top-div">
      Static Fields
    </div>
    <div class="grid-div vasion-html-table-default">
      <table>
        <thead>
          <tr>
            <th>
              <label class="vasion-html-table-header">Index Field</label>
            </th>
            <th>
              <label class="vasion-html-table-header">Compare</label>
            </th>
            <th>
              <label class="vasion-html-table-header">Search Value</label>
            </th>
            <th class="remove-row-column" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(criteria, index) in criteriaIndexFields" :key="index">
            <td>
              <VasionDropList
                v-slot="slotItem"
                v-model="criteria.SelectedField"
                :dataArray="allIndexFields"
                width="100%"
                type="plain-list"
                displayName="fieldTitle"
                valueName="indexFieldID"
              >
                {{ slotItem.item.fieldTitle }}
              </VasionDropList>
            </td>
            <td>
              <VasionDropList
                v-if="criteria.FieldType === 1 || criteria.FieldType === 2"
                v-slot="slotItem"
                v-model="criteria.SelectedComparisonType"
                :dataArray="stringCompare"
                width="100%"
                type="plain-list"
                displayName="name"
                valueName="id"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
              <VasionDropList
                v-if="criteria.FieldType === 3"
                v-slot="slotItem"
                v-model="criteria.SelectedComparisonType"
                :dataArray="dateCompare"
                width="100%"
                type="plain-list"
                displayName="name"
                valueName="id"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
            <td>
              <VasionInput
                v-if="criteria.FieldType > 0"
                v-model="criteria.searchValue"
                class="input-style"
                inputType="top-white"
              />
            </td>
            <td class="remove-row-column">
              <VasionButton :icon="'VasionDeleteIcon'" title="Remove Field" @vasionButtonClicked="removeField(criteria)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w100">
      <table class="w100">
        <tr>
          <td class="left-align">
            <VasionButton id="btnCancelStaticFields" :classProp="'primary-light'" @vasionButtonClicked="addField()">
              ADD FIELD
            </VasionButton>
          </td>
          <td class="right-align">
            {{ errorText }}
            <VasionButton id="btnCancelStaticFields" :classProp="'primary-light'" @vasionButtonClicked="cancelClick()">
              CANCEL
            </VasionButton>
            <VasionButton id="btnOKStaticFields" :classProp="'primary'" @vasionButtonClicked="okClick()">
              OK
            </VasionButton>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'IndexSearchStaticFields',
  props: {
    propAllIndexFields: {
      type: Array,
      default: () => [],
      required: true,
    },
    propCriteriaFields: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data: function () {
    return {
      allIndexFields: [],
      criteriaIndexFields: [],
      currentGroupID: -1,
      dateCompare: [
        {
          name: '',
          id: 0,
        },
        {
          name: 'Equals',
          id: 1,
        },
        {
          name: 'Not Equals',
          id: 5,
        },
        {
          name: 'Greater Than',
          id: 6,
        },
        {
          name: 'Less Than',
          id: 7,
        },
        {
          name: 'Greater Than or Equal To',
          id: 8,
        },
        {
          name: 'Less Than or Equal To',
          id: 9,
        },
      ],
      errorText: '',
      retData: [],
      stringCompare: [
        {
          name: '',
          id: 0,
        },
        {
          name: 'Equals',
          id: 1,
        },
        {
          name: 'Starts With',
          id: 2,
        },
        {
          name: 'Ends With',
          id: 3,
        },
        {
          name: 'Contains',
          id: 4,
        },
        {
          name: 'Not Equals',
          id: 5,
        },
      ],
    }
  },
  computed: {},
  watch: {
    criteriaIndexFields: {
      handler: function () {
        if (!this.criteriaIndexFields) {
          return
        }

        this.criteriaIndexFields.forEach((element) => {
          let fieldType = 0
          if (element.SelectedField && element.SelectedField.indexFieldID > 0) {
            fieldType = element.SelectedField.type
          }

          if (element.FieldType !== fieldType) {
            element.FieldType = fieldType
          }
        })
      },
      deep: true,
    },
  },
  created() {
    // Set the allIndexFields and criteriaIndexFields arrays based on the props

    // always start with the "OR" field
    this.allIndexFields = [
      {
        indexFieldID: 0,
        fieldTitle: '-OR-',
        type: 0,
      },
    ]

    // fill in the rest of the available index fields
    if (this.propAllIndexFields) {
      this.propAllIndexFields.forEach((field) => {
        this.allIndexFields.push({
          indexFieldID: field.value,
          fieldTitle: field.name,
          type: field.type,
        })
      })
    }

    this.criteriaIndexFields = []

    if (!this.propCriteriaFields || this.propCriteriaFields.length === 0) {
      // No criteria fields were entered - start the User off with a blank row
      this.criteriaIndexFields.push(this.getBlankCriteriaRow())
    } else {
      this.currentGroupID = -1
      this.propCriteriaFields.map(this.buildCriteriaIndexFields)
    }
  },
  methods: {
    addField() {
      this.criteriaIndexFields.push(this.getBlankCriteriaRow())
    },
    buildCriteriaIndexFields(propCriteria) {
      const foundField = this.allIndexFields.find((fieldElement) => {
          return fieldElement.indexFieldID === propCriteria.indexFieldID
        })

        if (foundField) {
          if (this.currentGroupID !== propCriteria.groupingID) {
            // it's a new group!  plug in an "OR" column if it's not the first row
            if (this.currentGroupID >= 0) {
              this.criteriaIndexFields.push(this.getBlankCriteriaRow())
            }

            this.currentGroupID = propCriteria.groupingID
          }

          let compareObject = null
          if (foundField.type === 3) {
            compareObject = this.dateCompare.find((element) => {
              return element.id === propCriteria.comparisonType
            })
          } else {
            compareObject = this.stringCompare.find((element) => {
              return element.id === propCriteria.comparisonType
            })
          }

          // finally, add the field to the list
          this.criteriaIndexFields.push({
            indexFieldID: propCriteria.indexFieldID,
            searchValue: propCriteria.searchValue,
            groupingID: propCriteria.groupingID,
            FieldType: propCriteria.type,
            SelectedField: foundField,
            SelectedComparisonType: compareObject,
          })
        }
    },
    buildRetData(criteria) {
      if (criteria.SelectedField.indexFieldID === 0) {
        // it's an OR field!  do a new group
        if (this.currentGroupID > 0) {
          this.currentGroupID += 1
        }
      } else {
        if (this.currentGroupID <= 0) {
          this.currentGroupID = 1
        }

        this.retData.push({
          groupingID: this.currentGroupID,
          indexFieldID: criteria.SelectedField.indexFieldID,
          searchValue: criteria.searchValue,
          comparisonType: criteria.SelectedComparisonType.id,
        })
      }
    },
    cancelClick() {
      this.$emit('cancelClick')
    },
    getBlankCriteriaRow() {
      return {
        searchValue: '',
        groupingID: 0,
        FieldType: 0,
        SelectedField: {
          indexFieldID: 0,
          fieldTitle: '-OR-',
          type: 0,
        },
        SelectedComparisonType: {
          name: '',
          id: 0,
        },
      }
    },
    okClick() {
      // The User wants to save the data.  look over their should and make sure they didn't do anything crazy
      // tell them all about it if they did - otherwise, format the data emit it
      this.errorText = ''

      this.retData = []
      if (!this.criteriaIndexFields || this.criteriaIndexFields.length === 0) {
        // no data to process, which is completely fine
        this.$emit('okClick', this.retData)
        return
      }

      // make sure the user entered a comparison setting and search value for everything
      const missingField = this.criteriaIndexFields.find((element) => {
        return element.SelectedField.id > 0 && (element.SelectedComparisonType.id <= 0 || !element.searchValue)
      })

      if (missingField) {
        this.errorText = `Please enter Compare and Search Values for ${$fieldsLabelPlural}`
        return
      }

      this.currentGroupID = -1
      this.criteriaIndexFields.map(this.buildRetData)
      this.$emit('okClick', this.retData)
    },
    removeField(criteria) {
      this.criteriaIndexFields = this.criteriaIndexFields.filter((element) => {
        return element !== criteria
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .grid-div {
    height: 315px;
    overflow: auto;
  }

  .left-align {
    text-align: left;
  }

  .main-div {
    height: 400px;
    background-color: white;
    margin: 32px 24px 32px 24px;
  }

  .remove-row-column {
    width: 50px;
  }

  .right-align {
    text-align: right;
  }

  .top-div {
    height: 50px;
  }

  .w100 {
    width: 100%;
  }

  td {
    padding-right: 10px;
    padding-left: 10px;
  }

</style>
